<script setup lang="ts">
const isOpen = defineModel<boolean>({
    required: true
})
</script>

<template>
   <ElementsModal :isOpen="isOpen" title="Conditions générales de vente" @close="isOpen = false">
        <template #content>
            Conditions Générales de vente à venir.
        </template>
   </ElementsModal>
</template>